import React from "react";
import { SocialIcon } from "react-social-icons";

function SocialBar(props) {
  // Strip Social medias from props
  let socials = {
    email: props.email,
    twitter: props.twitter,
    facebook: props.facebook,
    instagram: props.instagram,
    vimeo: props.vimeo,
    linkedin: props.linkedIn,
    personalWebsite: props.personalWebsite
  };

  // Create each social media button
  // TODO add personal website feature
  var socialComponents = [];
  for (var site in socials) {
    var value = socials[site];

    if (value) {
      var url = `https://www.${site}.com/${value}`;
      let bgColor = (site === "instagram") ? "#dd2a7b" : null;

      socialComponents.push(
        <SocialIcon
          url={site === "email" ? `mailto:${value}` : url}
          network={site === "email" ? "email" : null}
          style={{ margin: "2px", alignSelf: "end" }}
          bgColor={bgColor}
          target="_blank"
          key={site}
        />
      );
    }
  }

  return socialComponents;
}

export default SocialBar;
