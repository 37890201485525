/** @format */

import React from "react";
import BlockContent from "../block-content";
import SocialBar from "./socialBar";

import styled from "styled-components";
import { setRem } from "../../styles/styles";

const PersonPanel = ({
	name,
	_rawBio,
	program,
	institution,
	_rawResearchSummaryShort,
	keywords,
	className,
	shortVersion,
	...props
}) => {
	let keywordStr = [];
	keywords && keywords.forEach((item) => keywordStr.push(item.value));

	let socials = {
		email: props.email,
		twitter: props.twitter,
		facebook: props.facebook,
		instagram: props.instagram,
		vimeo: props.vimeo,
		linkedin: props.linkedIn,
		personalWebsite: props.personalWebsite,
	};

	const pstyle = {
		marginTop: "8px",
		marginBottom: "40px",
	};

	return (
		<div className={className}>
			<h1>{name}</h1>
			{!shortVersion && <BlockContent blocks={_rawBio || []} />}
			{shortVersion && (
				<BlockContent blocks={_rawResearchSummaryShort || []} />
			)}
			<h4>Program</h4>
			<p style={pstyle}>{program?.name}</p>
			<h4>Institution or Affiliation</h4>
			<p style={pstyle}>{institution?.name}</p>
			{keywordStr.length > 0 && (
				<div style={pstyle}>
					<h4>Research Areas</h4>
					<p style={pstyle}>
						<em>{keywordStr.join(", ")}</em>
					</p>
				</div>
			)}

			{/* {!shortVersion &&
        <SocialBar {...props} className="socialBar" />
      } */}

			<div>
				<h4>Connect</h4>
				<div style={pstyle}>
					{Object.keys(socials).map((keyName, index) => {
						if (socials[keyName]) {
							return (
								<div style={{ display: "flex" }}>
									<p
										style={{
											textTransform: "capitalize",
											fontStyle: "italic",
											marginBottom: "1.6px",
										}}
									>
										{keyName}:&nbsp;
									</p>
									<p
										style={{
											fontStyle: "italic",
											marginBottom: "1.6px",
										}}
									>
										{socials[keyName]}
									</p>
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};

export default styled(PersonPanel)`
	h1 {
		font-size: 56px !important;
		line-height: 1;
		text-indent: 0 !important;
		font-family: sans-serif;
		font-weight: normal;
		margin-bottom: 40px;
		margin-top: 40px;
		text-align: center;
	}
	p,
	h4 {
		font-family: serif;
		font-size: inherit !important;
		line-height: 1.2;
	}
	h4 {
		font-weight: normal;
		text-decoration: underline;
		margin-top: 18px;
		margin-bottom: ${setRem(8)};
	}
`;
