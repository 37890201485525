import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { setRem } from "../../styles/styles";
import PersonPanel from "../globals/PersonPanel";

const StyledNameCard = styled.div`
	display: grid;
	grid-template-columns: 15% 85%;

	* {
		font-size: ${setRem(10)};
		margin: 0;
		text-transform: capitalize;
		text-align: left;
	}
	h1 {
		height: 100%;
		display: inline-block;
	}
	h4 {
		display: inline-flex;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 95%;
	}
`;

const ProjectNameCard = (props) => {
	return (
		<StyledNameCard>
			<h1>{props.number}.</h1>
			<div>
				<h4>
					<em>{props.title},</em>
				</h4>
				<h4>{props.members[0].person.name},</h4>
				<h4>{format(new Date(props.publishedAt), "YYYY")}</h4>
			</div>
		</StyledNameCard>
	);
};

export default ProjectNameCard;
