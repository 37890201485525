import styled from "styled-components";
import React from "react";

const Container = styled.div`
  .projects {
    color: white;
    columns: 2;

    @media (max-width: 1140px) {
      columns: 1;
    }

    /*
    grid-auto-flow: dense;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: masonry;
    grid-template-columns: repeat(
      auto-fit,
      minmax(300px, var(--template-columns, 1fr))
    );
    grid-auto-rows: 5px;
    grid-gap: 30px;
    */

    padding: 100px 0;
  }
`

const MagazineContainer = ({ names, projects }) => {
  return (
    <Container>
      <div className="projects">{projects}</div>
    </Container>
  );
};

export default MagazineContainer;
