import React from "react";
import ProjectPreview from "./projectPreview";
import ProjectNameCard from "./ProjectNameCard";

/* Returns a list of project name tiles and project previews*/
export const ProjectCardList = ({ nodes, hideHovers, discipline, type }) => {
	let components = [];
	let previews = [];
	let nameCards = [];
	let number = 0;
	nodes.forEach((node) => {
		if (
			discipline === "all" ||
			(node.members[0]?.person.discipline &&
				node.members[0].person.discipline.name === discipline)
		) {
			number += 1;

			previews.push(
				<ProjectPreview {...node} hideHover={hideHovers} number={number} key={number} />
			);

			nameCards.push(
				<ProjectNameCard {...node} number={number} key={number} />
			);
		}
	});
	components.push(previews, nameCards);
	return components;
};
