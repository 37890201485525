/** @format */

import { Link } from "gatsby";
import React, { useState } from "react";
import { StandardImg } from "../img";
import { format } from "date-fns";
import { VideoPreview } from "../video";
import CardHover from "../globals/HoverCard";
import styled from "styled-components";
import { setColor, setRem } from "../../styles/styles";

const ProjectCard = styled(Link)`
	display: inline-block;
	overflow: hidden;
	text-decoration: none;
	padding-bottom: 20px;

	p {
		opacity: 0;
		color: black;
		font-size: 18px;
		font-family: serif;
		margin: 0;
	}

	&:hover p {
		opacity: 1;
	}


	@media (max-width: 1140px) {
		p {
			opacity: 1;
		}
	}

	img {
		margin: 0 0 4px;
		padding-bottom: 0;
		width: 100%;
	}
`;

const ProjectPreview = (props, { className }) => {
	// Select apropriate thumbnail
	/** TODO change video to video thumbs and pdf preview */

	let thumbnail = null;
	const [hover, setHover] = useState(false);

	if (props.videoURL && props.mainImage && props.mainImage.asset) {
		thumbnail = <StandardImg {...props} />;
	} else if (props.mainImage && props.mainImage.asset)
		thumbnail = <StandardImg {...props} />;

	return (
		<ProjectCard
			to={`/project/${props.slug?.current}`}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
		>
			{!props.hideHover && (
				<CardHover hover={hover} person={props.members[0]?.person} />
			)}
			{thumbnail}
			<p>
				<em>{props.title}</em>, Naarm/Melbourne: Victorian College of
				the Arts, {format(new Date(props.publishedAt), "YYYY")}
			</p>
		</ProjectCard>
	);
};

export default ProjectPreview;
