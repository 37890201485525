import React from "react";
import PersonPanel from "./PersonPanel";
import styled from "styled-components";

export const Hover = styled.div`
	background: white;
	color: black;
	height: 100vh;
	opacity: 0;
	padding: 120px 120px 50px;
	pointer-events: none;
	position: fixed;
	top: 0px;
	z-index: 1;
	width: calc(50% - 110px);
	opacity: 0;

	@media (hover: hover) {
		opacity: ${props => props.hover ? '1' : '0'};
	}

	${props => props.reverse
		? `
			right: calc(50% + 50px);
			border-right: 0;
		` : `
			left: calc(50% + 50px);
			border-left: 0;
		`
		}

	h1 {
    font-size: 56px !important;
		line-height: 1;
		text-indent: 0 !important;
		font-family: sans-serif;
    font-weight: normal;
    margin-bottom: 40px;
		text-align: center;
	}

	> div {
		padding: 0;
	}
`

const CardHover = ({ hover, reverse, person }) => {

	return (
		<Hover reverse={reverse} hover={hover}>
			<PersonPanel
				shortVersion
				{...person}
			/>
		</Hover>
	)
}

export default CardHover
